import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ManagerUserSelect } from 'components/manager-user-select'
import { SkillSelect } from 'components/skill-select'
import { UpdateTypeSelect } from 'components/update-type-select'
import { User } from 'store/modules/users'
import { WordFeedStoryMode, WorkFeedFilters } from '../work-feed.types'
import { WorkFeedNavVm } from './work-feed-nav-vm'
import * as SecondaryNav from 'components/secondary-nav'
import { WorkFeedLayout } from '../wrapper'
import { store } from 'store/index'
import { LayoutButton } from './layout-button'

type WorkFeedNavProps = {
  currentUser: User
  filters: WorkFeedFilters
  loadingSkills?: boolean
  onChange: (filters: WorkFeedFilters) => void
  layout: WorkFeedLayout
  onLayoutChange: (layout: WorkFeedLayout) => void
  onNewStory: () => void
  onCancelNewStory: () => void
  storyMode?: WordFeedStoryMode
}

export const WorkFeedNav = observer((props: WorkFeedNavProps) => {
  const {
    currentUser,
    filters,
    loadingSkills,
    onChange,
    layout,
    onLayoutChange,
  } = props

  const vm = React.useMemo(() => {
    return new WorkFeedNavVm(currentUser, filters, loadingSkills)
  }, [currentUser, filters, loadingSkills])

  const storiesEnabled = store.featureFlags.featureEnabled('stories')

  return (
    <SecondaryNav.Root data-element-id="work-feed-nav">
      <SecondaryNav.Header>Activity Feed</SecondaryNav.Header>
      <SecondaryNav.Body className="gap-4">
        {currentUser.isManager && (
          <ManagerUserSelect
            defaultValue={filters.user_id}
            onChange={(value) => onChange(vm.updateFilter('user_id', value))}
            user={currentUser}
          />
        )}
        {vm.showSkillSelect && (
          <SkillSelect
            defaultSkillIds={filters.skill_id}
            focusSkillIds={vm.focusSkillIds}
            onChange={(skillIds) =>
              onChange(vm.updateFilter('skill_id', skillIds))
            }
            skills={vm.skills}
            source="work-feed"
            triggerContent="All skills"
          />
        )}
        <UpdateTypeSelect
          defaultUpdateTypes={filters.update_type}
          onChange={(updateTypes) =>
            onChange(vm.updateFilter('update_type', updateTypes))
          }
        />
      </SecondaryNav.Body>
      <SecondaryNav.Actions>
        {storiesEnabled && (
          <LayoutButton layout={layout} onLayoutChange={onLayoutChange} />
        )}
      </SecondaryNav.Actions>
    </SecondaryNav.Root>
  )
})
