import cn from 'classnames'
import { MagnifyingGlass } from '@phosphor-icons/react'
import * as React from 'react'
import { TUpdateOption } from '../search-result-type-select.types'

export type TriggerProps = {
  value: TUpdateOption | null
}

export const TriggerContent = (props: TriggerProps) => {
  const { value } = props

  const content = value?.label || 'Relevant updates'

  return (
    <>
      <MagnifyingGlass
        weight="bold"
        className="text-gray-600 w-4 h-4"
        aria-hidden
      />
      <div className={cn(value && 'text-gray-900')}>{content}</div>
    </>
  )
}
