import { UpdateType } from '../../types/updates'
import { TUpdateOption } from './update-type-select.types'

export class UpdateTypeSelectVm {
  constructor(
    private defaultUpdateTypes: UpdateType[],
    private updateTypes: UpdateType[]
  ) {}

  get defaultValue(): readonly TUpdateOption[] {
    return this.options.filter((option) =>
      this.defaultUpdateTypes.includes(option.value)
    )
  }

  get options(): readonly TUpdateOption[] {
    return this.updateTypes.map((updateType) => ({
      label: this.updateTypeLabels[updateType],
      value: updateType,
    }))
  }

  private updateTypeLabels: Record<UpdateType, string> = {
    actions: 'Actions completed',
    checkins: 'Check-ins',
    feedback_received: 'Feedback received',
    feedback_requested: 'Feedback requested',
    focus_skills: 'Focus Skills',
    notes: 'Notes',
    promotions: 'Promotions',
    wins: 'Wins received',
  }
}
