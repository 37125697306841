import * as React from 'react'
import { BannerBase } from './banner-base'

export const UserDraftTeamBanner = () => {
  return (
    <BannerBase headline="Your team is not published. You won’t be able to Check-in.">
      Contact your admin or a team editor to get the team status updated.
    </BannerBase>
  )
}
