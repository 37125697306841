import { Dropdown } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import Select from 'react-select'
import { TriggerContent } from './components/trigger'
import {
  TUpdateOption,
  UpdateTypeSelectProps,
} from './update-type-select.types'
import * as Components from './components'
import { UpdateTypeSelectVm } from './update-type-select-vm'
import { UpdateType } from '../../types/updates'

export const allUpdateTypes: UpdateType[] = [
  'actions',
  'checkins',
  'feedback_received',
  'feedback_requested',
  'focus_skills',
  'notes',
  'promotions',
  'wins',
]

export const UpdateTypeSelect = observer((props: UpdateTypeSelectProps) => {
  const {
    defaultUpdateTypes = [],
    menuIsOpen,
    onChange,
    updateTypes = allUpdateTypes,
  } = props

  const vm = React.useMemo(() => {
    return new UpdateTypeSelectVm(defaultUpdateTypes, updateTypes)
  }, [defaultUpdateTypes, updateTypes])

  const [value, setValue] = React.useState<readonly TUpdateOption[] | null>(
    vm.defaultValue
  )

  const [open, setOpen] = React.useState(!!menuIsOpen)

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger
        data-element-id="update-select-trigger"
        variant="styled"
      >
        <TriggerContent updateTypes={updateTypes} value={value} />
      </Dropdown.Trigger>
      <Dropdown.Content
        align="start"
        forceMount={menuIsOpen || undefined}
        avoidCollisions={false}
      >
        <Select
          components={Components}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          onChange={(value) => {
            setValue(value)
            onChange?.(value.map((v) => v.value as UpdateType))
          }}
          value={value}
          menuIsOpen
          isMulti
          isSearchable={false}
          options={vm.options}
          onKeyDown={(event) => {
            if (event.key === 'Escape') setOpen(false)
          }}
          styles={{
            menu: () => ({}),
            groupHeading: () => ({}),
          }}
        />
      </Dropdown.Content>
    </Dropdown.Root>
  )
})
