import {
  convertValueToUserIds,
  convertValueToUsers,
} from 'components/manager-user-select/utils'
import { store } from 'store/index'
import { UpdateType } from 'app/packs/src/types/updates'
import { User } from 'store/modules/users'
import { WorkFeedFilters } from '../work-feed.types'

export class WorkFeedNavVm {
  constructor(
    private currentUser: User,
    private filters: WorkFeedFilters,
    private loadingSkills?: boolean
  ) {}

  get focusSkillIds() {
    let user: User | undefined
    if (!this.currentUser.isManager) user = this.currentUser

    const userIds = convertValueToUserIds(
      this.currentUser,
      this.filters.user_id
    )

    if (userIds.length === 1) user = store.users.byId(userIds[0])

    return user ? store.userSkills.focusedSkillIdsForUser(user.id) : []
  }

  get showSkillSelect() {
    return !this.loadingSkills && this.skills.length > 0
  }

  get skills() {
    if (!this.currentUser.isManager) return this.currentUser.sortedSkills()

    const users = convertValueToUsers(this.currentUser, this.filters.user_id)

    if (users.length > 1) {
      return store.skills.requiredForUsers(users)
    } else {
      return users[0]?.sortedSkills() || []
    }
  }

  updateFilter(
    filterKey: string,
    filterValue: string | string[] | UpdateType[]
  ) {
    return {
      ...this.filters,
      [filterKey]:
        Array.isArray(filterValue) && filterValue.length === 0
          ? undefined
          : filterValue,
    }
  }
}
