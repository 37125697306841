import {
  Checks,
  CircleWavyCheck,
  Clipboard,
  Megaphone,
  Note,
  Rocket,
  Star,
} from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { CheckboxIcon } from 'components/checkbox-icon'
import { components, OptionProps } from 'react-select'
import { TUpdateOption } from '../update-type-select.types'
import { UpdateType } from 'app/packs/src/types/updates'

const updateIcons = {
  actions: Checks,
  checkins: Clipboard,
  feedback_received: Megaphone,
  feedback_requested: Megaphone,
  focus_skills: Star,
  notes: Note,
  promotions: Rocket,
  wins: CircleWavyCheck,
}

const updateClassNames: Record<UpdateType, string> = {
  actions: 'text-green-600',
  checkins: 'text-pink-600',
  feedback_received: 'text-yellow-600',
  feedback_requested: 'text-yellow-600',
  focus_skills: 'text-green-600',
  notes: 'text-blue-600',
  promotions: 'text-orange-400',
  wins: 'text-green-400',
}

export const Option = (props: OptionProps<TUpdateOption, true>) => {
  const {
    isSelected,
    isFocused,
    label,
    data: { value },
  } = props

  const Icon = updateIcons[value as UpdateType]

  return (
    <components.Option
      {...props}
      className={cn(
        isFocused && 'bg-gray-50',
        isSelected && !isFocused && 'bg-transparent',
        'hover:!bg-gray-50 hover:cursor-pointer px-4 py-3 flex flex-row items-center gap-x-2 text-gray-900'
      )}
    >
      <CheckboxIcon checked={isSelected} />
      <Icon className={updateClassNames[value as UpdateType]} weight="bold" />
      <span data-selected={isSelected}>{label}</span>
    </components.Option>
  )
}
