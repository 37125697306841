import { Dropdown } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import Select from 'react-select'
import { TriggerContent } from './components/trigger'
import {
  TUpdateOption,
  SearchResultTypeSelectProps,
} from './search-result-type-select.types'
import * as Components from './components'
import { SearchResultTypeSelectVm } from './search-result-type-select-vm'
import { SearchUpdateType } from '../../types/updates'

export const allUpdateTypes: SearchUpdateType[] = [
  'relevant_updates',
  'all_updates',
]

export const SearchResultTypeSelect = observer(
  (props: SearchResultTypeSelectProps) => {
    const {
      defaultUpdateTypes = 'relevant_updates',
      menuIsOpen,
      onChange,
      updateTypes = allUpdateTypes,
    } = props

    const vm = React.useMemo(() => {
      return new SearchResultTypeSelectVm(defaultUpdateTypes, updateTypes)
    }, [defaultUpdateTypes, updateTypes])

    const [value, setValue] = React.useState<TUpdateOption | null>({
      label: 'Relevant updates',
      value: 'relevant_updates',
    })

    const [open, setOpen] = React.useState(!!menuIsOpen)

    return (
      <Dropdown.Root open={open} onOpenChange={setOpen}>
        <Dropdown.Trigger
          data-element-id="update-select-trigger"
          variant="styled"
        >
          <TriggerContent value={value} />
        </Dropdown.Trigger>
        <Dropdown.Content
          align="start"
          forceMount={menuIsOpen || undefined}
          avoidCollisions={false}
        >
          <Select
            defaultValue={value}
            components={Components}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            onChange={(value) => {
              if (value) {
                setValue(value)
                onChange?.(value?.value)
              }
            }}
            value={value}
            menuIsOpen
            isSearchable={false}
            options={vm.options}
            onKeyDown={(event) => {
              if (event.key === 'Escape') setOpen(false)
            }}
            styles={{
              menu: () => ({}),
              groupHeading: () => ({}),
            }}
          />
        </Dropdown.Content>
      </Dropdown.Root>
    )
  }
)
