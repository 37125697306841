import { SearchUpdateType } from '../../types/updates'
import { TUpdateOption } from './search-result-type-select.types'

export class SearchResultTypeSelectVm {
  constructor(
    private defaultUpdateTypes: SearchUpdateType,
    private updateTypes: SearchUpdateType[]
  ) {}

  get defaultValue(): readonly TUpdateOption[] {
    return this.options.filter((option) =>
      this.defaultUpdateTypes.includes(option.value)
    )
  }

  get options(): readonly TUpdateOption[] {
    return this.updateTypes.map((updateType) => ({
      label: this.updateTypeLabels[updateType],
      value: updateType,
    }))
  }

  private updateTypeLabels: Record<SearchUpdateType, string> = {
    relevant_updates: 'Relevant updates',
    all_updates: 'All updates',
  }
}
