import { openModal } from 'app/packs/src/utils/open-modal'
import * as React from 'react'
import { BannerBase } from './banner-base'

export const UserMissingPositionBanner = () => {
  const onClick = () => {
    openModal('/org_users/me/edit')
  }

  return (
    <BannerBase
      headline="Select a position"
      buttonTitle="Select your position"
      onClick={onClick}
    >
      See where you sit in your team’s framework, and track your progress over
      time.
    </BannerBase>
  )
}
