import { isNumber } from 'remirror'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActivityFeed } from 'components/activity-feed'
import { CreateFirstTeamBanner } from '../create-first-team-banner'
import { GetSetUp } from '../get-set-up'
import { LoadingContent } from 'components/loading-content'
import { useStore } from 'store/context'
import { WorkFeedNav } from './work-feed-nav'
import { useFeed } from 'components/activity-feed/use-feed'
import { WordFeedStoryMode, WorkFeedProps } from './work-feed.types'
import { WorkFeedLayout, WorkFeedWrapper } from './wrapper'
import { ActivityGrid } from 'components/activity-feed/grid/activity-grid'
import { store } from 'store/index'
import { Input } from 'components/atoms/input'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { StoryFeedNav } from './work-feed-nav/story-feed-nav'
import { Activity } from 'store/modules/activities'
import { Button, Loader } from 'src/design-system'

export const WorkFeed = observer((props: WorkFeedProps) => {
  const {
    currentUser,
    initialLayout = 'list',
    initialStoryMode = 'none',
    addingToStoryId,
  } = props

  const { teams, userSkills } = useStore()
  const [layout, setLayout] = React.useState<WorkFeedLayout>(initialLayout)
  const [teamCount, setTeamCount] = React.useState<number | null>(null)
  const [storyMode, setStoryMode] =
    React.useState<WordFeedStoryMode>(initialStoryMode)

  const editingStory = storyMode === 'editing'
  const creatingStory = storyMode === 'creating'

  const defaultFilters =
    editingStory || creatingStory
      ? {
          user_id: currentUser.id,
          search_result_type: 'relevant_updates' as const,
        }
      : undefined

  const {
    sortedActivities,
    onChange,
    hasNextPage,
    loadingSkills,
    filters,
    initialLoading,
    onLoadMore,
    vm,
  } = useFeed({ currentUser, defaultFilters })

  const fetchTeamCount = React.useCallback(async () => {
    const { meta } = await teams.fetchAll({ page: { size: 0 } })
    if (meta?.total && isNumber(meta.total)) setTeamCount(meta.total)
  }, [teams])

  const story = store.stories.byId(addingToStoryId)

  const [searchTerm, setSearchTerm] = React.useState<string>(story?.title || '')
  const [searchResults, setSearchResults] = React.useState<Activity[]>([])
  const [searchAnswer, setSearchAnswer] = React.useState('')
  const [searching, setSearching] = React.useState(false)
  const [performedInitialSearch, setPerformedInitialSearch] =
    React.useState(false)

  React.useEffect(() => {
    if (currentUser.isAdmin) fetchTeamCount()
  }, [currentUser, fetchTeamCount])

  const doSearch = React.useCallback(async () => {
    if (searchTerm) {
      setSearching(true)

      const res = await store.activities.fetchAll({
        filter: { story: searchTerm },
        include: ['record'],
      })

      if (res.success) {
        // display only activities returned by search
        const ids = Object.keys(res?.data?.activities || {})
        setSearchResults(ids.map((id) => store.activities.byId(id) as Activity))
        setSearchAnswer(res?.meta?.explain || '')
      }

      setSearching(false)
    }
  }, [searchTerm])

  React.useEffect(() => {
    if (addingToStoryId && !performedInitialSearch) {
      // search for story title on modal open
      doSearch()
      setPerformedInitialSearch(true)
    }
  }, [addingToStoryId, performedInitialSearch, doSearch])

  const storySearchEnabled = store.featureFlags.featureEnabled('stories')

  const displayableActivities =
    searchResults.length && filters.search_result_type == 'relevant_updates'
      ? searchResults
      : sortedActivities

  const noSearchResults = searchTerm && searchResults.length == 0 && !searching

  return (
    <div className="flex flex-col items-center">
      {(editingStory || creatingStory) && storySearchEnabled && (
        <>
          <div className=" w-full px-10 py-3 border-b-px border-gray-100 border-solid">
            <div className="flex justify-start items-center">
              <MagnifyingGlass className="w-4 h-4 mr-2" />
              <Input
                onChange={(value) => setSearchTerm(value || '')}
                value={searchTerm}
                className="w-full"
                nonFocusedPlaceholder="e.g. show me where I've communcated well"
              />
              <Button
                size="lg"
                variant="outline"
                onClick={doSearch}
                className="ml-2"
                disabled={searching}
              >
                {searching ? <Loader className="w-4 h-4" /> : 'Search'}
              </Button>
            </div>
            {filters.search_result_type == 'relevant_updates' && !searching && (
              <>
                {(searchAnswer || noSearchResults) && (
                  <div className="flex justify-start items-center ml-6 pt-3 italic">
                    {searchAnswer}
                    {noSearchResults && (
                      <>
                        Sorry, we didn&apos;t find any updates that match your
                        search.
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <StoryFeedNav
            currentUser={currentUser}
            filters={filters}
            loadingSkills={loadingSkills}
            onChange={onChange}
          />
        </>
      )}
      {storyMode == 'none' && (
        <WorkFeedNav
          currentUser={currentUser}
          filters={filters}
          loadingSkills={loadingSkills || searching}
          onChange={onChange}
          layout={layout}
          onLayoutChange={(l) => {
            setLayout(l)
            setStoryMode('none')
          }}
          onNewStory={() => {
            setLayout('grid')
            setStoryMode('creating')
          }}
          onCancelNewStory={() => {
            setStoryMode('none')
            setLayout('list')
          }}
          storyMode={storyMode}
        />
      )}
      <WorkFeedWrapper layout={layout}>
        {layout === 'list' && (
          <>
            <GetSetUp />
            {currentUser.isAdmin && teamCount === 0 && (
              <CreateFirstTeamBanner />
            )}
          </>
        )}
        <LoadingContent loading={initialLoading || searching}>
          {layout === 'list' ? (
            <ActivityFeed
              activities={sortedActivities}
              data-element-id="work-feed-activity-feed"
              focusSkillIds={userSkills.focusedSkillIdsForUser(currentUser.id)}
              hasNextPage={hasNextPage}
              onLoadMoreClick={onLoadMore}
              showFocusSkillName={true}
            />
          ) : (
            <ActivityGrid
              activities={displayableActivities}
              onLoadMore={hasNextPage && !searchTerm ? onLoadMore : undefined}
              storyMode={storyMode}
              addingToStoryId={addingToStoryId}
              onSubmitStory={async (addedUpdates, addingToStoryId) => {
                await vm.upsertStory(addedUpdates, addingToStoryId)
                if (addingToStoryId) {
                  await store.stories.fetchOne(
                    addingToStoryId,
                    {
                      include: ['story_items', 'story_items.storyable'],
                    },
                    {
                      bypassCache: true,
                    }
                  )
                }
              }}
            />
          )}
        </LoadingContent>
      </WorkFeedWrapper>
    </div>
  )
})
