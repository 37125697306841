import { Sparkle, Stack } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { components, OptionProps } from 'react-select'
import { TUpdateOption } from '../search-result-type-select.types'
import { SearchUpdateType } from 'app/packs/src/types/updates'

const updateIcons = {
  relevant_updates: Sparkle,
  all_updates: Stack,
}

const updateClassNames: Record<SearchUpdateType, string> = {
  relevant_updates: 'text-pink-600',
  all_updates: 'text-grey-600',
}

export const Option = (props: OptionProps<TUpdateOption, false>) => {
  const {
    isSelected,
    isFocused,
    label,
    data: { value },
  } = props

  const Icon = updateIcons[value as SearchUpdateType]

  return (
    <components.Option
      {...props}
      className={cn(
        isFocused && 'bg-gray-50',
        isSelected && !isFocused && 'bg-theme-95',
        'hover:!bg-gray-50 hover:cursor-pointer px-4 py-3 flex flex-row items-center gap-x-2 text-gray-900'
      )}
    >
      <Icon
        className={updateClassNames[value as SearchUpdateType]}
        weight="bold"
      />
      <span data-selected={isSelected}>{label}</span>
    </components.Option>
  )
}
