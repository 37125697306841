import { openModal } from 'app/packs/src/utils/open-modal'
import * as React from 'react'
import { BannerBase } from './banner-base'

export const UserMissingManagerBanner = () => {
  const onClick = () => {
    openModal('/org_users/me/edit')
  }

  return (
    <BannerBase
      headline="Select your manager"
      buttonTitle="Select your manager"
      onClick={onClick}
    >
      Share work updates and Check-ins with your manager, so they can support
      your progress.
    </BannerBase>
  )
}
