import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { SkillSelect } from 'components/skill-select'
import { UpdateTypeSelect } from 'components/update-type-select'
import { User } from 'store/modules/users'
import { WorkFeedFilters } from '../work-feed.types'
import { WorkFeedNavVm } from './work-feed-nav-vm'
import { SearchResultTypeSelect } from 'components/search-result-type-select'

type StoryFeedNavProps = {
  currentUser: User
  filters: WorkFeedFilters
  loadingSkills?: boolean
  onChange: (filters: WorkFeedFilters) => void
}

export const StoryFeedNav = observer((props: StoryFeedNavProps) => {
  const { currentUser, filters, loadingSkills, onChange } = props

  const vm = React.useMemo(() => {
    return new WorkFeedNavVm(currentUser, filters, loadingSkills)
  }, [currentUser, filters, loadingSkills])

  return (
    <div className="flex w-full justify-start items-center gap-4 px-10 pt-10">
      {vm.showSkillSelect && (
        <SkillSelect
          defaultSkillIds={filters.skill_id}
          focusSkillIds={vm.focusSkillIds}
          onChange={(skillIds) =>
            onChange(vm.updateFilter('skill_id', skillIds))
          }
          skills={vm.skills}
          source="work-feed"
          triggerContent="All skills"
        />
      )}
      <UpdateTypeSelect
        defaultUpdateTypes={filters.update_type}
        onChange={(updateTypes) =>
          onChange(vm.updateFilter('update_type', updateTypes))
        }
      />
      <SearchResultTypeSelect
        onChange={(searchResultType) =>
          onChange(vm.updateFilter('search_result_type', searchResultType))
        }
      />
    </div>
  )
})
