import * as React from 'react'
import { BannerBase } from './banner-base'

export const UserMissingPositionSkillsBanner = () => {
  return (
    <BannerBase headline="Your position doesn't have any skills.">
      Contact your admin or a team editor to get them set up.
    </BannerBase>
  )
}
