import * as React from 'react'
import { BannerBase } from './banner-base'

type AdminMissingPositionSkillsBannerProps = {
  teamSlug: string
}

export const AdminMissingPositionSkillsBanner = (
  props: AdminMissingPositionSkillsBannerProps
) => {
  const { teamSlug } = props
  return (
    <BannerBase
      headline="Your position doesn't have any skills."
      buttonTitle="Add skills"
      onClick={() => {
        window.location.href = `/teams/${teamSlug}/skills`
      }}
    >
      Add skills to stay on track and unlock all of Progression.
    </BannerBase>
  )
}
