import { Button } from 'src/design-system'
import * as React from 'react'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import createFirstTeam from '../../../../images/create-first-team.svg'

export const CreateFirstTeamBanner: React.VFC = () => {
  return (
    <div className="bg-gray-50 mb-3 p-6 rounded-lg w-full">
      <div className="flex justify-between items-start">
        <img alt="" className="mb-6" height="80px" src={createFirstTeam} />
      </div>
      <div className="font-bold mb-3 text-2xl md:text-3xl text-gray-900">
        Create your first team to get started
      </div>
      <p className="text-gray-600 text-base">
        Build your team&apos;s framework to unlock all of Progression. It&apos;s
        easy to get started with our free library of templates from teams
        you&apos;ve heard of.
      </p>
      <Button
        as="a"
        className="btn btn-brand mt-6"
        href="/teams/new"
        onClick={() =>
          trackEvent(
            '$track_create_first_team_button_work_feed_home_page_clicked'
          )
        }
      >
        Create a Team
      </Button>
    </div>
  )
}
