import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { AdminMissingPositionSkillsBanner } from './banners/admin-missing-position-skills-banner'
import { UserDraftTeamBanner } from './banners/user-draft-team-banner'
import { UserMissingManagerBanner } from './banners/user-missing-manager-banner'
import { UserMissingPositionBanner } from './banners/user-missing-position-banner'
import { UserMissingPositionSkillsBanner } from './banners/user-missing-position-skills-banner'

export const GetSetUp = observer(() => {
  const { currentUser, userSkills, positions } = store

  const content = React.useMemo(() => {
    const availablePositions = !!positions.forOrg.length
    const canSelfAssignPosition = currentUser?.org?.canSelfAssignPosition
    const draftTeam = currentUser?.team?.isDraft
    const hasManager = currentUser?.manager
    const hasPosition = currentUser?.position
    const hasPositionSkills =
      currentUser && userSkills.requiredForUser(currentUser.id).length > 0
    const isAdmin = currentUser?.isAdmin
    const isEditor = currentUser?.team?.hasEditor(currentUser?.id)
    const isMember = currentUser?.isMember

    return [
      {
        condition: isMember && !hasManager && canSelfAssignPosition,
        component: <UserMissingManagerBanner key="missing-manager" />,
      },
      {
        condition:
          isMember &&
          !hasPosition &&
          availablePositions &&
          canSelfAssignPosition,
        component: <UserMissingPositionBanner key="missing-position" />,
      },
      {
        condition: isMember && !isEditor && hasPosition && !hasPositionSkills,
        component: <UserMissingPositionSkillsBanner key="missing-skills" />,
      },
      {
        condition: isMember && !isEditor && draftTeam,
        component: <UserDraftTeamBanner key="draft-team" />,
      },
      {
        condition:
          (isAdmin || isEditor) &&
          currentUser?.team &&
          hasPosition &&
          !hasPositionSkills,
        component: (
          <AdminMissingPositionSkillsBanner
            teamSlug={currentUser?.team?.slug ?? ''}
            key="admin-missing-skills"
          />
        ),
      },
    ]
  }, [currentUser, userSkills, positions.forOrg])

  const components = content.reduce(
    (acc, { condition, component }) => (condition ? [...acc, component] : acc),
    [] as React.ReactElement[]
  )

  if (components.length === 0) return null

  return (
    <div className="flex justify-center">
      <div className="pb-2 w-full">
        <div className="text-base font-bold mb-3">Get set up</div>
        {components}
      </div>
    </div>
  )
})
