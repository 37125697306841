import { Button } from 'src/design-system'
import * as React from 'react'

type BannerProps = {
  headline: string
  children: React.ReactNode
  onClick?: () => void
  buttonTitle?: string
}

export const BannerBase = (props: BannerProps) => {
  const { headline, children, onClick, buttonTitle } = props
  return (
    <div className="bg-gray-50 w-full rounded-lg px-6 py-4 mb-4">
      <div className="flex flex-row gap-x-10 items-center">
        <div className="flex-1">
          <div className="font-bold text-base mb-1">{headline}</div>
          <p>{children}</p>
        </div>
        <div>
          {onClick && (
            <Button className="btn btn-brand nowrap" onClick={onClick}>
              {buttonTitle}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
