import { UpdateType } from 'app/packs/src/types/updates'
import cn from 'classnames'
import { Stack } from '@phosphor-icons/react'
import * as React from 'react'
import { TUpdateOption } from '../update-type-select.types'

export type TriggerProps = {
  value: readonly TUpdateOption[] | null
  updateTypes: UpdateType[]
}

export const TriggerContent = (props: TriggerProps) => {
  const { value, updateTypes } = props

  let content = 'All types'

  if (value) {
    if (value.length === 1) content = value[0].label
    if (value.length === 2) content = value.map((v) => v.label).join(', ')
    if (value.length > 2) content = `${value[0].label}, +${value.length - 1}`
  }

  const hasValue = value && value.length > 0

  return (
    <>
      <Stack
        weight="bold"
        className={cn(
          hasValue && value.length !== updateTypes.length
            ? 'text-theme-50'
            : 'text-gray-600',
          'w-4 h-4'
        )}
        aria-hidden
      />
      <div className={cn(value && value.length > 0 && 'text-gray-900')}>
        {content}
      </div>
    </>
  )
}
