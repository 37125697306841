import { ActivityTypeFilters } from 'store/modules/activities/filters'
import { convertValueToUserIds } from 'components/manager-user-select/utils'
import { currentUrl } from 'app/packs/src/utils/url-helpers'
import { store } from 'store/index'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { UpdateType } from 'app/packs/src/types/updates'
import { User } from 'store/modules/users'
import { WorkFeedFilters } from './work-feed.types'
import { AllowedStoryUpdateType } from 'components/activity-feed/grid/context'

const RecordTypeConverter = {
  actions: 'Action',
  checkins: 'Checkin',
  feedback_received: 'FeedbackItem',
  feedback_requested: 'FeedbackRequest',
  focus_skills: 'SkillsUser',
  notes: 'Note',
  promotions: 'PositionChange',
  wins: 'Win',
} as const

export class WorkFeedVm {
  constructor(
    private currentUser: User,
    private setHasNextPage: (hasNextPage: boolean) => void
  ) {}

  perPage = 20

  get defaultFilters() {
    const filters: WorkFeedFilters = {}
    const searchParams = currentUrl().searchParams

    const skillIds = searchParams.getAll('skill_id')
    if (skillIds.length > 0) filters.skill_id = skillIds

    const updateTypes = searchParams.getAll('update_type')
    if (updateTypes.length > 0) {
      filters.update_type = updateTypes as UpdateType[]
    }

    const userId = searchParams.get('user_id')
    if (userId) filters.user_id = userId

    return filters
  }

  activityFilters(filters: WorkFeedFilters) {
    const activityFilters: ActivityTypeFilters = {}

    if (filters.skill_id) activityFilters.skill_id = filters.skill_id

    if (filters.update_type) {
      activityFilters.record_type = this.convertUpdateTypes(filters.update_type)
    }

    activityFilters.user_id = convertValueToUserIds(
      this.currentUser,
      filters.user_id
    )

    return activityFilters
  }

  applyFilters(filters: WorkFeedFilters) {
    window.history.replaceState(window.history.state, '', this.url(filters))
    trackEvent('$track_work_feed_nav_filtered', filters)

    const userIds = this.userIds(filters.user_id)
    if (userIds.length === 1) store.userSkills.fetchForUser(userIds[0])
  }

  async fetchActivities(filters: WorkFeedFilters, pageNumber: number) {
    const { meta } = await store.activities.fetchAllWithAllIncludes(
      this.perPage,
      this.activityFilters(filters),
      pageNumber
    )

    const hasNextPage = meta?.pages && meta.pages > pageNumber
    this.setHasNextPage(!!hasNextPage)
  }

  async fetchSkills() {
    if (!this.currentUser.isManager) return

    const userIds = this.userIds(this.defaultFilters.user_id)

    if (userIds.length === 1) {
      await store.userSkills.fetchForUser(userIds[0])
    } else {
      await store.positions.fetchAll({ include: ['skills'] })
    }
  }

  async upsertStory(
    updates: AllowedStoryUpdateType[],
    addingToStoryId?: string
  ) {
    if (!addingToStoryId) {
      const storyResult = await store.stories.create({})
      if (!storyResult.success || !storyResult.data) return
      const storyId = Object.keys(storyResult.data.stories)[0]

      await Promise.all(
        updates.map((update) =>
          store.storyItems.create({
            story: storyId,
            storyableId: update.id,
            storyableType: update._type.name,
          })
        )
      )

      window.location.href = `/stories/${storyId}`
    } else {
      const story = store.stories.byId(addingToStoryId)

      // find all the items not in the updates
      const itemsToRemove = story?.storyItems.filter((item) => {
        return !updates.find((update) => {
          return (
            item.storyable._type.name === update._type.name &&
            item.storyable.id === update.id
          )
        })
      })

      if (itemsToRemove && itemsToRemove.length > 0) {
        await Promise.all(
          await itemsToRemove.map((item) => {
            store.storyItems.destroy(item.id)
          })
        )
      }

      await Promise.all(
        await updates.map((update) => {
          store.storyItems.create({
            story: addingToStoryId,
            storyableId: update.id,
            storyableType: update._type.name,
          })
        })
      )
    }
  }

  private convertUpdateTypes(updateTypes: UpdateType[]) {
    return updateTypes.map((updateType) => RecordTypeConverter[updateType])
  }

  private url(filters: WorkFeedFilters) {
    const url = new URL(window.location.origin + window.location.pathname)

    if (filters.skill_id) {
      filters.skill_id.forEach((skillId) =>
        url.searchParams.append('skill_id', skillId)
      )
    }

    if (filters.update_type) {
      filters.update_type.forEach((updateType) =>
        url.searchParams.append('update_type', updateType)
      )
    }

    if (filters.user_id) {
      url.searchParams.append('user_id', filters.user_id)
    }

    return url
  }

  private userIds(value?: string) {
    return convertValueToUserIds(this.currentUser, value)
  }
}
