import { camelize } from '@ridi/object-case-converter'
import { WorkFeedVm } from 'components/home-page/work-feed/work-feed-vm'
import { WorkFeedFilters } from 'components/home-page/work-feed/work-feed.types' // eslint-disable-line import/extensions
import React from 'react'
import { useStore } from 'store/context'
import { User } from 'store/modules/users'

export const useFeed = ({
  currentUser,
  defaultFilters,
}: {
  currentUser: User
  defaultFilters?: WorkFeedFilters
}) => {
  const { activities } = useStore()
  const [hasNextPage, setHasNextPage] = React.useState(false)
  const [loadingSkills, setLoadingSkills] = React.useState(true)
  const [pageNumber, setPageNumber] = React.useState(1)

  const vm = React.useMemo(() => {
    return new WorkFeedVm(currentUser, setHasNextPage)
  }, [currentUser, setHasNextPage])

  const [filters, setFilters] = React.useState<WorkFeedFilters>(
    defaultFilters || vm.defaultFilters
  )

  const [limit, setLimit] = React.useState(vm.perPage)

  React.useEffect(() => {
    const fetchSkills = async () => {
      await vm.fetchSkills()
      setLoadingSkills(false)
    }

    fetchSkills()
  }, [vm])

  React.useEffect(() => {
    vm.fetchActivities(filters, pageNumber)
  }, [filters, pageNumber, vm])

  const onChange = (filters: WorkFeedFilters) => {
    setFilters(filters)
    setLimit(vm.perPage)
    setPageNumber(1)
    vm.applyFilters(filters)
  }

  const sortedActivities = activities.filteredActivities(
    camelize(vm.activityFilters(filters)),
    limit
  )

  const initialLoading = activities.initialLoading() || loadingSkills
  const loading = initialLoading || activities.loading

  const onLoadMore = () => {
    setLimit(limit + vm.perPage)
    setPageNumber(pageNumber + 1)
  }

  return {
    hasNextPage,
    loadingSkills,
    onChange,
    pageNumber,
    sortedActivities,
    filters,
    initialLoading,
    loading,
    onLoadMore,
    vm,
  }
}
